<template>
  <Navbar />
  <div class="admin-dashboard">
    <div class="content">
      <div class="sales-header">
        <h2 class="title">Últimas Ventas</h2>
        <div class="search-bar">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Buscar por cliente o ID"
            @input="filterVentas"
          />
        </div>

        <!-- Filtro de estado de venta -->
        <div class="status-filter">
          <label for="status">Filtrar por Estado:</label>
          <select id="status" v-model="selectedStatus" @change="filterVentas">
            <option value="">Todos</option>
            <option value="0">Procesando</option>
            <option value="1">Completado</option>
            <option value="2">Rechazado</option>
            <option value="10">Venta manual</option>
          </select>
        </div>
      </div>
      <div v-if="loading" class="loading">Cargando ventas...</div>
      <div v-else class="ventas-list">
        <table class="ventas-table">
          <thead>
            <tr>
              <th @click="sortBy('id')" :class="getSortClass('id')">ID</th>
              <th @click="sortBy('cliente')" :class="getSortClass('cliente')">
                Cliente
              </th>
              <th @click="sortBy('total')" :class="getSortClass('total')">
                Monto
              </th>
              <th
                @click="sortBy('fecha_venta')"
                :class="getSortClass('fecha_venta')"
              >
                Fecha
              </th>
              <th @click="sortBy('status')" :class="getSortClass('status')">
                Estado
              </th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="venta in filteredVentas" :key="venta.id">
              <td data-label="ID">{{ venta.id }}</td>
              <td data-label="Cliente">{{ venta.cliente }}</td>
              <td data-label="Monto">{{ formatPrice(venta.total) }}</td>
              <td data-label="Fecha">{{ formatDate(venta.fecha_venta) }}</td>
              <td data-label="Estado">{{ formatStatus(venta.status) }}</td>
              <td data-label="Acciones">
                <button @click="viewDetails(venta.id)" class="btn">
                  Ver Detalles
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="selectedVenta" class="modal">
        <div class="modal-content">
          <h3>Detalles de la Venta</h3>
          <button @click="closeDetails" class="btn cancel">Cerrar</button>
          <div v-if="ventaDetalles">
            <p><strong>Nombre:</strong> {{ ventaDetalles.venta.nombre }}</p>
            <p><strong>Apellido:</strong> {{ ventaDetalles.venta.apellido }}</p>
            <p><strong>RUT:</strong> {{ ventaDetalles.venta.rut }}</p>
            <p><strong>Email:</strong> {{ ventaDetalles.venta.email }}</p>
            <p><strong>Teléfono:</strong> {{ ventaDetalles.venta.telefono }}</p>
            <p>
              <strong>Monto:</strong>
              {{ formatPrice(ventaDetalles.venta.total) }}
            </p>
            <p>
              <strong>Fecha:</strong>
              {{ formatDate(ventaDetalles.venta.fecha_venta) }}
            </p>
            <p>
              <strong>Estado:</strong>
              {{ formatStatus(ventaDetalles.venta.status) }}
            </p>

            <h4>Productos:</h4>
            <ul>
              <li v-for="producto in ventaDetalles.detalles" :key="producto.id">
                {{ producto.nombre }} - {{ producto.cantidad }} x
                {{ formatPrice(producto.precio_unitario) }}
              </li>
            </ul>

            <h4>Transacciones:</h4>
            <ul>
              <li
                v-for="transaccion in ventaDetalles.transacciones"
                :key="transaccion.id"
              >
                Referencia: {{ transaccion.reference }} - Monto:
                {{ formatPrice(transaccion.monto) }} - Fecha:
                {{ formatDate(transaccion.fecha_transaccion) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api";
import Navbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Navbar,
  },

  data() {
    return {
      ventas: [],
      filteredVentas: [],
      searchQuery: "",
      selectedStatus: "1", // Cambiado de "1" a ""
      loading: true,
      error: null,
      selectedVenta: null,
      ventaDetalles: null,
      sortKey: "fecha_venta",
      sortOrders: {
        id: 0,
        cliente: 0,
        total: 0,
        fecha_venta: -1,
        status: 0,
      },
    };
  },

  async created() {
    await this.loadVentas(); // Carga las ventas
    // this.sortBy("fecha_venta"); // Eliminado
    // this.filterVentas(); // Ya se llama dentro de loadVentas
  },

  watch: {
    searchQuery() {
      this.filterVentas();
    },
    selectedStatus() {
      this.filterVentas();
    },
  },

  methods: {
    sortBy(key) {
      // Alterna entre 0 (desactivado), 1 (ascendente), -1 (descendente)
      if (this.sortOrders[key] === 0) {
        this.sortOrders[key] = -1; // Primero, orden descendente
      } else if (this.sortOrders[key] === -1) {
        this.sortOrders[key] = 1; // Segundo, orden ascendente
      } else {
        this.sortOrders[key] = 0; // Tercero, sin orden
      }

      // Desactiva la ordenación de otras columnas cuando se ordena por una nueva columna
      this.sortKey = key;
      Object.keys(this.sortOrders).forEach((k) => {
        if (k !== key) {
          this.sortOrders[k] = 0; // Restablece el sort de otras columnas
        }
      });

      // Re-aplica el filtro y la ordenación
      this.filterVentas();
    },

    getSortClass(key) {
      if (this.sortOrders[key] === -1) {
        return "sorted-desc"; // Clase para descendente
      } else if (this.sortOrders[key] === 1) {
        return "sorted-asc"; // Clase para ascendente
      } else {
        return ""; // Sin orden
      }
    },

    async loadVentas() {
      try {
        const response = await apiClient.get("/ventas");
        this.ventas = response.data;
        this.filterVentas(); // Aplica el filtro después de cargar las ventas
      } catch (error) {
        this.error = "Error al cargar las ventas.";
      } finally {
        this.loading = false;
      }
    },

    filterVentas() {
      const query = this.searchQuery.toLowerCase();

      // Filtrar primero por búsqueda de cliente o ID
      let filtered = this.ventas.filter(
        (venta) =>
          venta.cliente.toLowerCase().includes(query) ||
          venta.id.toString().includes(query)
      );

      // Filtrar por estado seleccionado
      if (this.selectedStatus !== "") {
        filtered = filtered.filter(
          (venta) => venta.status.toString() === this.selectedStatus
        );
      }

      // Ordenar después de filtrar
      if (this.sortKey && this.sortOrders[this.sortKey] !== 0) {
        filtered.sort((a, b) => {
          if (a[this.sortKey] < b[this.sortKey])
            return -1 * this.sortOrders[this.sortKey];
          if (a[this.sortKey] > b[this.sortKey])
            return 1 * this.sortOrders[this.sortKey];
          return 0;
        });
      }

      this.filteredVentas = filtered; // Asignar ventas filtradas
    },

    async viewDetails(id) {
      try {
        const response = await apiClient.get(`/ventas/${id}`);
        this.ventaDetalles = response.data;
        this.selectedVenta = id;
      } catch (error) {
        console.error("Error al cargar los detalles de la venta:", error);
        alert("Error al cargar los detalles de la venta.");
      }
    },
    closeDetails() {
      this.selectedVenta = null;
      this.ventaDetalles = null;
    },
    formatPrice(value) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleDateString("es-CL");
    },
    formatStatus(status) {
      const statuses = {
        0: "Procesando",
        1: "Completado",
        2: "Rechazado",
        10: "Venta manual",
        // Agrega más estados si es necesario
      };
      return statuses[status] || "Desconocido";
    },
  },
};
</script>

<style scoped>
/* Contenedor general */
.admin-dashboard {
  padding-top: 80px; /* Para compensar la altura del navbar */
  margin-left: 2em;
  margin-right: 2em;
}

/* Centrado del título */
.title {
  text-align: center;
}

/* Barra de búsqueda */
.search-bar {
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos para el campo de búsqueda */
.search-bar input {
  padding: 10px;
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Lista de ventas */
.ventas-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Tabla de ventas */
.ventas-table {
  width: 100%;
  border-collapse: collapse;
}

/* Celdas y encabezados de la tabla */
.ventas-table th,
.ventas-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Encabezado de la tabla */
.ventas-table th {
  background-color: #f4f4f4;
}

/* Botones */
.btn {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

/* Botones de cancelación */
.btn.cancel {
  background-color: #6c757d;
}

.btn:hover {
  opacity: 0.9;
}

/* Modal para detalles de la venta */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
}

/* Ordenación de columnas con íconos */
th {
  cursor: pointer;
  position: relative;
}

th:after {
  content: "▲▼"; /* Por defecto, sin orden */
  font-size: 0.7em;
  margin-left: 8px;
  color: #888;
}

th.sorted-asc:after {
  content: "▲"; /* Icono para ascendente */
  color: #000;
}

th.sorted-desc:after {
  content: "▼"; /* Icono para descendente */
  color: #000;
}

.status-filter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-filter select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Media Queries para la tabla responsiva en móviles */
@media (max-width: 768px) {
  .ventas-table,
  .ventas-table thead,
  .ventas-table tbody,
  .ventas-table th,
  .ventas-table td,
  .ventas-table tr {
    display: block; /* Cambia la tabla a bloques */
  }

  .ventas-table thead tr {
    display: none; /* Oculta los encabezados en móviles */
  }

  .ventas-table td {
    padding: 10px;
    position: relative;
    text-align: right; /* Alinea el texto a la derecha */
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .ventas-table td::before {
    content: attr(
      data-label
    ); /* Usa el atributo data-label para mostrar el nombre de la columna */
    position: absolute;
    left: 10px;
    width: 50%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  .ventas-table td:last-child {
    border-bottom: 0;
  }

  .ventas-list {
    width: 100%;
    padding: 0 15px;
  }
}
</style>
