<template>
  <div>
    <Navbar @open-carrito="toggleCarrito" />
    <Carrito v-if="mostrarCarrito" @close="toggleCarrito" />

    <!-- Modal de registro para sesiones de prueba -->
    <div v-if="mostrarModal" class="modal-overlay">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>

        <!-- Paso 1: Formulario de registro -->
        <div v-if="step === 1">
          <h2>¡Obtén una sesión de prueba gratuita!</h2>
          <p>
            Ingresa tus datos y participa en nuestra selección para recibir una
            sesión de solarium de prueba. Te notificaremos por correo
            electrónico si eres seleccionado.
          </p>

          <form @submit.prevent="enviarFormulario">
            <label for="nombre">Nombre:</label>
            <input
              type="text"
              id="nombre"
              v-model="form.nombre"
              required
              placeholder="Tu nombre"
            />

            <label for="nombre">Apellido:</label>
            <input
              type="text"
              id="apellido"
              v-model="form.apellido"
              required
              placeholder="Tu apellido"
            />

            <label for="rut">RUT:</label>
            <input
              type="text"
              id="rut"
              v-model="form.rut"
              required
              placeholder="12.345.678-9"
              @input="formatRut"
            />

            <label for="email">Correo electrónico:</label>
            <input
              type="email"
              id="email"
              v-model="form.email"
              required
              placeholder="tu@correo.com"
            />

            <label for="telefono">Número de teléfono:</label>
            <input
              type="tel"
              id="telefono"
              v-model="form.telefono"
              required
              placeholder="9 1234 5678"
            />

            <button type="submit" class="btn-enviar" :disabled="isSubmitting">Enviar</button>
          </form>
        </div>

        <!-- Paso 2: Confirmación de éxito -->
        <div v-if="step === 2">
          <h2>¡Registro completado!</h2>
          <p>
            ¡Gracias por registrarte! Te notificaremos por correo si has sido
            seleccionado para una sesión de prueba.
          </p>
          <button @click="closeModal" class="btn-enviar">Cerrar</button>
        </div>

        <!-- Paso 3: Error al enviar -->
        <div v-if="step === 3">
          <h2>Ocurrió un problema</h2>
          <p>
            Lo sentimos, hubo un error al procesar tu solicitud. Por favor,
            inténtalo más tarde.
          </p>
          <button @click="closeModal" class="btn-enviar">Cerrar</button>
        </div>

        <!-- Paso 4: Cliente ya registrado -->
        <div v-if="step === 4">
          <h2>Ya estás registrado</h2>
          <p>
            Nuestro sistema indica que ya has sido registrado previamente. Si
            necesitas más información, por favor revisa tu correo electrónico o
            contáctanos para obtener más detalles.
          </p>
          <button @click="closeModal" class="btn-enviar">Cerrar</button>
        </div>
      </div>
    </div>

    <div>
      <main class="main-content">
        <section class="banner">
          <div class="banner-content">
            <h1>Inspirado en Bali</h1>
            <h2>Hecho en Chile</h2>
          </div>
        </section>
        <section class="lineaCafe">
          <div class="marquee">
            <div class="marquee-content">
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
            </div>
          </div>
        </section>
        <section class="sesiones-section">
          <Sesiones @agregar-al-carrito="agregarAlCarrito" />
        </section>
        <section class="productos-section">
          <Productos @agregar-al-carrito="agregarAlCarrito" />
        </section>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import Productos from "../components/ProductosPage.vue";
import Sesiones from "../components/Sesiones.vue";
import Navbar from "../components/NavbarTop.vue";
import Carrito from "../components/Carrito.vue";
import Footer from "../components/Footer.vue";
import state from "../state";
import apiClient from "../api";

export default {
  components: {
    Productos,
    Sesiones,
    Navbar,
    Carrito,
    Footer,
  },
  data() {
    return {
      mostrarCarrito: false,
      mostrarModal: false, // Mostrar modal al cargar la página
      form: {
        nombre: "",
        apellido: "",
        rut: "",
        rutSinFormato: "",
        email: "",
        telefono: "",
      },
      step: 1, // Step actual del modal, empieza en 1 (formulario)
      isSubmitting: false,
    };
  },
  methods: {
    toggleCarrito() {
      this.mostrarCarrito = !this.mostrarCarrito;
    },
    agregarAlCarrito(producto) {
      state.agregarAlCarrito(producto);
    },
    closeModal() {
      this.mostrarModal = false;
      localStorage.setItem("modalVisto", "true"); // Marcar el modal como visto
    },
    formatRut() {
      // Eliminar puntos y guiones del RUT ingresado
      let value = this.form.rut.replace(/\./g, "").replace(/-/g, "");
      this.form.rutSinFormato = value; // Almacenar el RUT sin formato

      // Formatear el RUT con puntos y guión para mostrar en el input
      if (value.length > 1) {
        value =
          value.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          "-" +
          value.slice(-1);
      }
      this.form.rut = value;
    },
    async enviarFormulario() {
      if (this.isSubmitting) return;
      if (
        this.form.nombre &&
        this.form.apellido &&
        this.form.rut &&
        this.form.email &&
        this.form.telefono
      ) {
        try {
          this.isSubmitting = true;
          const response = await apiClient.post("/ventas/potentialUser", {
            rut: this.form.rutSinFormato,
            email: this.form.email,
            nombre: this.form.nombre,
            apellido: this.form.apellido,
            telefono: this.form.telefono,
          });

          if (response.status === 200) {
            this.step = 2; // Cambia al paso de éxito
            localStorage.setItem("modalVisto", "true");
          } else if (response.status === 201) {
            this.step = 4; // Cambia al paso de cliente ya registrado
          } else {
            this.step = 3; // Cambia al paso de error
          }
        } catch (error) {
          this.step = 3; // Muestra el error si hay una excepción
        } finally {
          // Habilitar el botón cuando la solicitud haya terminado
          this.isSubmitting = false;
        }
      } else {
        alert("Por favor, completa todos los campos obligatorios.");
      }
    },
  },
};
</script>

<style scoped>
.main-content {
  margin-top: 3.5em;
  padding-top: 1em;
  text-align: center;
  flex: 1;
  width: 100%;
}

.banner {
  background-image: url("../assets/banner.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  width: 100%;
}

.banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.banner h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.banner h2 {
  font-size: 1.8rem;
  margin: 0;
}

.lineaCafe {
  height: 3em;
  background-color: #7f5a35;
  color: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.marquee {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.marquee-content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: marquee 60s linear infinite;
  width: 100%;
}

.marquee-content span {
  display: inline-block;
  padding-right: 2rem;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

.productos-section {
  margin-top: 2rem;
  width: 100%;
}

.sesiones-section {
  width: 100%;
  margin-top: 2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 2em;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  position: relative;
}

.modal-content h2 {
  font-size: 1.8rem;
  margin-bottom: 1em;
}

.modal-content p {
  font-size: 1rem;
  margin-bottom: 1.5em;
}

.modal-content label {
  display: block;
  text-align: left;
  margin-bottom: 0.5em;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.modal-content .btn-enviar {
  background-color: #7f5a35; /* Marrón */
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.1em;
  width: 100%;
}

.modal-content .btn-enviar:hover {
  opacity: 0.85;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.5rem;
  color: #f44336;
  cursor: pointer;
}
</style>
