import { reactive } from 'vue';

const state = reactive({
  carrito: JSON.parse(localStorage.getItem('carrito')) || [],
});

const agregarAlCarrito = (producto) => {
  const existe = state.carrito.find((item) => item.id === producto.id);
  if (existe) {
    existe.cantidad += 1;
  } else {
    state.carrito.push({ ...producto, cantidad: 1 });
  }
  localStorage.setItem('carrito', JSON.stringify(state.carrito));
};

const removeFromCarrito = (id) => {
  state.carrito = state.carrito.filter((item) => item.id !== id);
  localStorage.setItem('carrito', JSON.stringify(state.carrito));
};

const clearCarrito = () => {
  state.carrito = [];
  localStorage.setItem('carrito', JSON.stringify(state.carrito));
};

export default {
  state,
  agregarAlCarrito,
  removeFromCarrito,
  clearCarrito,
};
