<template>
  <div class="sesiones-container">
    <Navbar />
    <div class="sesiones">
      <div class="header">
        <h1>Agenda tu Sesión de Solarium</h1>
      </div>

      <div v-if="loading" class="loading">Cargando calendario...</div>
      <div v-else>
        <div class="calendar-container">
          <h2>Selecciona una fecha</h2>
          <FullCalendar :options="calendarOptions" />
        </div>

        <!-- Contenedor de horas disponibles con ref para desplazar la vista -->
        <div
          v-if="selectedDate"
          class="horas-disponibles"
          ref="horasDisponiblesSection"
        >
          <h3>Horas disponibles para {{ selectedDateFormatted }}</h3>
          <div v-if="loadingHoras">Cargando horas disponibles...</div>
          <div v-else-if="horasDisponibles.length > 0">
            <ul>
              <li
                v-for="hora in horasDisponibles"
                :key="hora"
                @click="selectHora(hora)"
              >
                {{ hora }}
              </li>
            </ul>
          </div>
          <div v-else>No hay horas disponibles para esta fecha.</div>
        </div>
      </div>

      <!-- Modal para ingresar RUT -->
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>

          <!-- Paso 1: Ingresar RUT -->
          <div v-if="step === 1">
            <h2>Confirma tu sesión</h2>
            <h3>
              Para el {{ selectedDateFormatted }} a las {{ selectedHora }}
            </h3>
            <form @submit.prevent="checkSesionesDisponibles()">
              <label for="rut">Ingrese su RUT:</label>
              <input
                class="rut-input"
                v-model="rut"
                id="rut"
                type="text"
                @input="formatRut"
                required
              />

              <!-- Texto de aceptación de términos -->
              <p class="acceptance-text">
                Al agendar esta sesión, aceptas nuestros
                <router-link to="/terminos-condiciones" target="_blank"
                  >Términos y Condiciones</router-link
                >
                y nuestra
                <router-link to="/politica-privacidad" target="_blank"
                  >Política de Privacidad</router-link
                >.
              </p>

              <button class="rut-button" type="submit">
                Verificar Sesiones
              </button>
            </form>
          </div>

          <!-- Paso 2: Confirmar agendamiento -->
          <div v-else-if="step === 2">
            <h2>Confirmación de Sesión</h2>
            <h3>
              Tienes <b>{{ sesionesDisponibles }}</b> sesiones disponibles.
              <br />
              Si agendas esta sesión, te quedarán <b>{{ sesionesRestantes }}</b
              >.
            </h3>
            <p>
              ¿Deseas confirmar la sesión para el {{ selectedDateFormatted }} a
              las {{ selectedHora }}?
            </p>
            <button
              class="rut-button"
              @click="agendarSesion"
              :disabled="isSubmitting"
            >
              Confirmar y Agendar
            </button>
            <button class="cerrar-button" @click="closeModal">Cancelar</button>
          </div>

          <!-- Paso 3: Confirmación de sesión agendada -->
          <div v-else-if="step === 3">
            <h2>Sesión Agendada</h2>
            <h3>
              Su sesión ha sido agendada para el {{ selectedDateFormatted }} a
              las {{ selectedHora }}.
            </h3>
            <p>
              Le quedan <b>{{ sesionesRestantes }}</b> sesiones disponibles.
            </p>
            <button class="rut-button" @click="confirmAndReload">Cerrar</button>
          </div>

          <!-- Paso 4: No hay sesiones disponibles para el RUT ingresado -->
          <div v-else-if="step === 4">
            <h2>Sin sesiones disponibles</h2>
            <h3>
              El RUT ingresado no tiene sesiones asociadas. Si deseas agendar
              una sesión, por favor dirígete a nuestra sección de compra.
            </h3>
            <button class="comprar-sesion-button" @click="goToSesiones">
              Comprar Sesión
            </button>
            <button class="cerrar-button" @click="closeModal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import apiClient from "../api";
import Navbar from "../components/NavbarTop.vue";
import Footer from "../components/Footer.vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

export default {
  components: {
    Navbar,
    Footer,
    FullCalendar,
  },
  data() {
    return {
      loading: true,
      loadingHoras: false,
      selectedDate: null,
      selectedHora: null,
      horasDisponibles: [],
      events: [],
      rut: "",
      rutSinFormato: "",
      showModal: false,
      step: 1,
      sesionesDisponibles: null, // Sesiones que tiene disponibles
      sesionesRestantes: null,
      isSubmitting: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        events: this.events,
        validRange: {
          start: "2024-10-21",
        },
        timeZone: "local",
        locales: [
          {
            ...esLocale,
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            monthNamesShort: [
              "ENE",
              "FEB",
              "MAR",
              "ABR",
              "MAY",
              "JUN",
              "JUL",
              "AGO",
              "SEP",
              "OCT",
              "NOV",
              "DIC",
            ],
          },
        ],
        locale: "es",
        headerToolbar: {
          left: "title",
          center: "",
          right: "prev,next",
        },
        allDaySlot: false,
        dateClick: this.handleDateClick,
        hiddenDays: [0],
      },
    };
  },
  computed: {
    selectedDateFormatted() {
      const [year, month, day] = this.selectedDate.split("-");
      const diasSemana = [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ];
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      const date = new Date(year, month - 1, day);
      const diaSemanaTexto = diasSemana[date.getDay()];

      return `${diaSemanaTexto}, ${day} de ${meses[month - 1]} de ${year}`;
    },
  },
  methods: {
    async checkSesionesDisponibles() {
      try {
        const response = await apiClient.post("/sesiones/verificar-sesiones", {
          rut: this.rutSinFormato,
        });

        if (response.status === 200) {
          this.sesionesDisponibles = response.data.sesionesDisponibles; // Obtener la cantidad de sesiones disponibles
          this.sesionesRestantes = this.sesionesDisponibles - 1; // Mostrar cuántas quedarán después de agendar
          this.step = 2; // Pasar al paso de confirmación
        } else if (response.status === 302) {
          this.step = 4; // No hay sesiones disponibles
        }
      } catch (error) {
        console.error("Error al verificar sesiones disponibles:", error);
        this.step = 4; // Mostrar que no hay sesiones disponibles en caso de error
      }
    },

    async loadBookedSlots() {
      try {
        const response = await apiClient.get(`/sesiones/agendadas`);
        this.events = response.data.map((slot) => {
          const fecha = slot.fecha_sesion.split("T")[0];
          return {
            title: "Reservado",
            start: `${fecha}T${slot.hora_inicio}`,
          };
        });
      } catch (error) {
        console.error("Error al cargar las horas agendadas:", error);
      } finally {
        this.loading = false;
      }
    },

    goToSesiones() {
      this.$router.push("/sesiones");
    },

    loadHorasDisponibles() {
      this.loadingHoras = true;

      const horasTrabajo = this.generarHorasDisponibles();
      const horasReservadas = this.events
        .filter((event) => event.start.includes(this.selectedDate))
        .map((event) => event.start.split("T")[1].slice(0, 5));

      this.horasDisponibles = horasTrabajo.filter(
        (hora) => !horasReservadas.includes(hora.slice(0, 5))
      );

      this.loadingHoras = false;
    },

    generarHorasDisponibles() {
      const horas = [];
      const horaInicio = 9;
      let horaFin = 19;

      // Convertir la fecha seleccionada a un objeto Date sin zona horaria
      const selectedDateObj = new Date(this.selectedDate + "T00:00:00");
      console.log("Fecha seleccionada (ajustada):", selectedDateObj);

      const diaSemana = selectedDateObj.getUTCDay(); // 0 = Domingo, 6 = Sábado (en UTC)
      const fechaLimite = new Date("2024-11-09T00:00:00");

      // Si es sábado y la fecha es igual o posterior al 9 de noviembre de 2024, limitar a las 14:00
      if (diaSemana === 6 && selectedDateObj >= fechaLimite) {
        horaFin = 14;
        console.log(
          "Horario limitado para sábados a partir del 9 de noviembre de 2024."
        );
      }

      for (let hora = horaInicio; hora < horaFin; hora++) {
        horas.push(`${hora.toString().padStart(2, "0")}:00`);
        horas.push(`${hora.toString().padStart(2, "0")}:30`);
      }

      return horas;
    },

    handleDateClick(info) {
      const selected = info.dateStr.split("T")[0];
      this.selectedDate = selected;
      this.selectedHora = null;

      // Desplazar hacia las horas disponibles
      this.$nextTick(() => {
        const section = this.$refs.horasDisponiblesSection;
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      });

      this.loadHorasDisponibles();
    },

    selectHora(hora) {
      this.selectedHora = hora;
      this.step = 1;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    formatRut() {
      let value = this.rut.replace(/\./g, "").replace(/-/g, "");
      this.rutSinFormato = value;

      if (value.length > 1) {
        value =
          value.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
          "-" +
          value.slice(-1);
      }
      this.rut = value;
    },

    async agendarSesion() {
      if (this.isSubmitting) return;
      try {
        this.isSubmitting = true;
        if (this.selectedDate && this.selectedHora && this.rut) {
          let back_response = await apiClient.post("/sesiones/agendar/", {
            fecha_sesion: this.selectedDate,
            hora_inicio: this.selectedHora,
            rut: this.rutSinFormato,
          });

          if (back_response.status === 200) {
            this.sesionesRestantes = back_response.data.sesionesRestantes; // Actualizar las sesiones restantes
            this.step = 3; // Sesión agendada exitosamente
          } else if (back_response.status === 302) {
            this.step = 4; // No hay sesiones disponibles para el RUT ingresado
          }
        } else {
          alert("Debe seleccionar una fecha, una hora y un RUT válido.");
        }
      } catch (error) {
        console.error("Error al agendar la sesión:", error);
        this.step = 4; // Si hay un error de servidor, mostrar step 4
      } finally {
        // Habilitar el botón cuando la solicitud haya terminado
        this.isSubmitting = false;
      }
    },

    confirmAndReload() {
      window.location.reload();
    },
  },
  mounted() {
    this.loadBookedSlots();
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.sesiones-container {
  background-color: #f9f9f9;
  min-height: 100vh;
  padding-top: 8em;
}

.sesiones {
  max-width: 50em;
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 3em;
}

.selected-date {
  background-color: #e6b800 !important;
  color: white !important;
  border-radius: 50%;
}

.header {
  text-align: center;
}

.loading {
  font-size: 1.5em;
  color: #888;
  text-align: center;
}

.calendar-container {
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.calendar-container h2 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.horas-disponibles {
  margin-top: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.horas-disponibles ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  padding: 0;
  list-style-type: none;
}

.horas-disponibles li {
  padding: 10px;
  border: 1px solid #ddd;
  cursor: pointer;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.horas-disponibles li:hover {
  background-color: #7f5a35;
  color: white;
}

.btn-agendar {
  background-color: #7f5a35;
  color: #000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-agendar:hover {
  background-color: #e6b800;
}

/* Modal styling */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #333;
}

.modal-content h3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #555;
}

.close {
  color: red;
  float: right;
  font-size: 24px;
  cursor: pointer;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #333;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #555;
}

.rut-input {
  width: 95%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-top: 10px;
  font-size: 1rem;
}

.rut-button {
  background-color: #936638;
  color: #000;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.rut-button:hover {
  background-color: #e6b800;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.acceptance-text {
  font-size: 0.9rem;
  color: #555;
  margin-top: 15px;
  text-align: center;
}

.acceptance-text a {
  color: #3498db;
  text-decoration: none;
}

.acceptance-text a:hover {
  text-decoration: underline;
}

.comprar-sesion-button {
  background-color: #7f5a35; /* Marrón */
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.comprar-sesion-button:hover {
  opacity: 0.85;
}

.cerrar-button {
  background-color: #ffd200;
  color: black;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: 10px;
  width: 100%;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.cerrar-button:hover {
  opacity: 0.85;
}

@media (max-width: 480px) {
  .modal-content {
    width: 90%;
  }
}
</style>
