<template>
  <div class="privacidad-container">
    <Navbar />
    <div class="privacidad">
      <h1>Política de Privacidad</h1>
      <p>
        En BaliSolarium S.P.A., respetamos tu privacidad y nos comprometemos a
        proteger tu información personal. Esta política describe cómo
        recopilamos, usamos y protegemos tu información cuando utilizas nuestros
        servicios.
      </p>

      <h2>1. Información que Recopilamos</h2>
      <p>
        1.1. Información personal: Al utilizar nuestros servicios, podemos
        recopilar tu nombre, RUT, número de teléfono, dirección de correo
        electrónico y detalles de las sesiones agendadas.
      </p>
      <p>
        1.2. Información de pago: Los pagos se procesan a través de Getnet.
        Nosotros no almacenamos ni tenemos acceso a tu información de tarjeta de
        crédito o débito.
      </p>
      <p>
        1.3. Información automática: Podemos recopilar información sobre tu
        dispositivo, navegador y actividad en nuestro sitio web a través de
        cookies u otras tecnologías de seguimiento.
      </p>

      <h2>2. Uso de la Información</h2>
      <p>
        2.1. Utilizamos la información que recopilamos para proporcionar
        nuestros servicios, procesar pagos, agendar sesiones, enviar
        confirmaciones y recordatorios de sesiones, y comunicarnos contigo en
        caso de cambios o cancelaciones.
      </p>
      <p>
        2.2. La información también puede ser utilizada para mejorar nuestros
        servicios y para fines de análisis internos.
      </p>

      <h2>3. Compartición de la Información</h2>
      <p>
        3.1. BaliSolarium S.P.A. no vende, alquila ni comparte tu información
        personal con terceros, excepto en los siguientes casos: - Cuando sea
        necesario para procesar los pagos a través de Getnet. - Para cumplir con
        requisitos legales o proteger nuestros derechos. - En caso de fusión o
        adquisición de la empresa, donde la información puede ser transferida a
        los nuevos propietarios.
      </p>

      <h2>4. Seguridad de la Información</h2>
      <p>
        4.1. BaliSolarium S.P.A. adopta medidas de seguridad razonables para
        proteger tu información personal de accesos no autorizados,
        alteraciones, divulgaciones o destrucción.
      </p>
      <p>
        4.2. Aunque tomamos medidas de seguridad adecuadas, no podemos
        garantizar la seguridad total de la información transmitida a través de
        Internet.
      </p>

      <h2>5. Derechos del Usuario</h2>
      <p>
        5.1. Tienes derecho a solicitar acceso, corrección o eliminación de tu
        información personal en cualquier momento.
      </p>
      <p>
        5.2. Para ejercer estos derechos, puedes contactarnos en
        contacto@balisolarium.cl o llamarnos al +56 934800610.
      </p>

      <h2>6. Cambios en la Política de Privacidad</h2>
      <p>
        6.1. Nos reservamos el derecho de modificar esta Política de Privacidad
        en cualquier momento. Las modificaciones serán notificadas a través de
        nuestros canales oficiales y entrarán en vigor en la fecha de su
        publicación.
      </p>

      <h2>7. Contacto</h2>
      <p>
        Si tienes preguntas o inquietudes sobre esta Política de Privacidad, puedes contactarnos en:
      </p>
      <p>
        BaliSolarium S.P.A.
      </p>
      <p>
        Dirección: Av. Gabriela Mistral 1386
      </p>
      <p>
        Teléfono: +56 934800610
      </p>
      <p>
        Email: contacto@balisolarium.cl
      </p>

      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from "../components/NavbarTop.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "PoliticaPrivacidad",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>
.privacidad-container {
  background-color: #f5f5f5;
}

.privacidad {
  max-width: 90%;
  margin: auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 8em;
}

h1,
h2 {
  color: #333;
}

p {
  margin-bottom: 20px;
  line-height: 1.6;
}
</style>
