<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
  name: 'App',
  setup() {
    useHead({
      titleTemplate: 'Bali Solarium', // Complementar el título con el nombre del sitio
      meta: [
        {
          charset: 'utf-8', // Codificación de caracteres
        },
        {
          name: 'viewport', // Meta tag para el diseño responsivo
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'description',
          content:
            'Descubre Bali Solarium en Los Ángeles, Chile. Lo último en tecnología de solarium vertical con colágeno para un bronceado perfecto y rejuvenecedor.',
        },
        {
          name: 'keywords',
          content: 'solarium, colágeno, bronceado, Los Ángeles, Chile, Bali, belleza',
        },
        {
          property: 'og:title',
          content: 'Bali Solarium - Inspirado en Bali, Hecho en Chile',
        },
        {
          property: 'og:description',
          content: 'Bronceado con tecnología de solarium y colágeno en Los Ángeles, Chile.',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: 'https://www.balisolarium.cl', // Reemplaza con la URL de tu sitio web
        },
      ],
    });
  },
};
</script>

<style>
body,
html,
#app {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  width: 100%;
  overflow-x: hidden;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

router-view {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
