<template>
  <Navbar />
  <div class="admin-dashboard">
    <div class="content">
      <h2 class="title">Productos Tops</h2>
      <div v-if="loading" class="loading">Cargando productos tops...</div>
      <div v-else class="productos-tops">
        <div
          v-for="producto in topProductos"
          :key="producto.id"
          class="producto"
        >
          <div v-if="producto.placeholder" class="placeholder">
            <p>No hay producto</p>
            <button @click="selectReplacement(producto)" class="btn">
              Agregar Producto
            </button>
          </div>
          <div v-else>
            <img
              :src="producto.imagen"
              alt="Imagen del producto"
              class="producto-imagen"
            />
            <div class="producto-detalles">
              <h3>{{ producto.nombre }}</h3>
              <p>{{ producto.descripcion }}</p>
              <p>{{ formatPrice(producto.precio) }}</p>
              <button @click="selectReplacement(producto)" class="btn">
                Reemplazar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showReplacement" class="replacement-modal">
        <div class="modal-content">
          <h3>Seleccionar Nuevo Producto</h3>
          <div class="productos-list">
            <div
              v-for="producto in productos"
              :key="producto.id"
              class="producto"
              @click="replaceTopProduct(selectedTop, producto)"
            >
              <img
                :src="producto.imagen"
                alt="Imagen del producto"
                class="producto-imagen"
              />
              <div class="producto-detalles">
                <h3>{{ producto.nombre }}</h3>
                <p>{{ formatPrice(producto.precio) }}</p>
              </div>
            </div>
          </div>
          <button @click="closeReplacement" class="btn cancel">Cancelar</button>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/AdminNavbar.vue";
import apiClient from "@/api";

export default {
  name: "AdminDashboard",
  components: {
    Navbar,
  },
  data() {
    return {
      topProductos: [],
      productos: [],
      selectedTop: null,
      showReplacement: false,
      loading: true,
      error: null,
    };
  },
  async created() {
    await this.loadTopProductos();
  },
  methods: {
    async loadTopProductos() {
      try {
        const response = await apiClient.get("/productos/top");
        this.topProductos = response.data;
        this.fillPlaceholders();
      } catch (error) {
        this.error = "Error al cargar los productos tops.";
      } finally {
        this.loading = false;
      }
    },
    async loadProductos() {
      try {
        const response = await apiClient.get("/productos/all");
        this.productos = response.data;
      } catch (error) {
        this.error = "Error al cargar los productos.";
      }
    },
    async replaceTopProduct(currentTop, newTop) {
      try {
        await apiClient.put("/productos/top/swap", {
          currentTopId: currentTop.id,
          newTopId: newTop.id,
        });
        alert("Producto top actualizado correctamente.");
        this.showReplacement = false;
        await this.loadTopProductos(); // Recargar la lista de productos tops
      } catch (error) {
        console.error("Error al actualizar el producto top:", error);
        alert("Error al actualizar el producto top.");
      }
    },
    selectReplacement(producto) {
      this.selectedTop = producto;
      this.showReplacement = true;
      this.loadProductos();
    },
    closeReplacement() {
      this.showReplacement = false;
      this.selectedTop = null;
    },
    fillPlaceholders() {
      const requiredPlaceholders = 5 - this.topProductos.length;
      for (let i = 0; i < requiredPlaceholders; i++) {
        this.topProductos.push({ id: `placeholder-${i}`, placeholder: true });
      }
    },
    formatPrice(value) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },
  },
};
</script>

<style scoped>
.admin-dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 2em;
}

.content {
  flex: 1;
  padding: 20px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.loading {
  text-align: center;
  font-size: 1.2em;
}

.productos-tops,
.productos-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.producto {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  width: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
}

.producto:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.producto-imagen {
  max-width: 100%;
  border-radius: 10px;
}

.producto-detalles h3 {
  font-size: 1.2em;
  margin: 10px 0;
}

.producto-detalles p {
  margin: 5px 0;
}

.btn {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px 0;
}

.btn.cancel {
  background-color: #6c757d;
}

.btn:hover {
  opacity: 0.9;
}

.replacement-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  max-height: 80%; /* Altura máxima del modal */
  overflow-y: auto; /* Habilitar scroll vertical */
}

.placeholder {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
}

.producto {
  cursor: pointer;
}
</style>
