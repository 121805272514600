import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createHead } from '@vueuse/head';  // Importar @vueuse/head

// Crear la instancia de la aplicación
const app = createApp(App);

// Crear el head manager para manejar las etiquetas meta
const head = createHead();

// Usar las herramientas necesarias en la app
app.use(router);
app.use(store);
app.use(head);  // Usar @vueuse/head

// Montar la aplicación en el elemento #app
app.mount('#app');
