<template>
  <div>
    <Navbar />
    <div class="edit-products">
      <h2 class="title">Editar Productos</h2>
      <div class="search-bar">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Buscar por ID o nombre"
          @input="filterProductos"
        />
      </div>
      <div v-if="loading" class="loading">Cargando productos...</div>
      <div v-else class="productos-list">
        <div
          v-for="producto in filteredProductos"
          :key="producto.id"
          class="producto"
        >
          <img
            :src="producto.imagen"
            alt="Imagen del producto"
            class="producto-imagen"
          />
          <div class="producto-detalles">
            <h3>{{ producto.nombre }}</h3>
            <p>{{ producto.descripcion }}</p>
            <p>{{ formatPrice(producto.precio) }}</p>
            <div class="action-btns">
              <button @click="editProduct(producto)" class="btn">Editar</button>
              <button @click="deleteProduct(producto.id)" class="btn delete">
                Eliminar
              </button>
            </div>
            <div class="action-btns">
              <button
                @click="toggleStatus(producto)"
                :class="[
                  'btn',
                  producto.status === 1 ? 'desactivar' : 'activar',
                ]"
                class="btn-status"
              >
                {{ producto.status === 1 ? "Desactivar" : "Activar" }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isEditing" class="modal">
        <div class="modal-content">
          <h3>Editar Producto</h3>
          <div class="edit-container">
            <form @submit.prevent="updateProduct" class="product-form">
              <div class="form-group">
                <label for="editNombre">Nombre del producto</label>
                <input
                  type="text"
                  v-model="editNombre"
                  id="editNombre"
                  placeholder="Nombre del producto"
                  required
                />
              </div>
              <div class="form-group">
                <label for="editDescripcion">Descripción</label>
                <textarea
                  v-model="editDescripcion"
                  id="editDescripcion"
                  placeholder="Descripción"
                  rows="3"
                  required
                ></textarea>
              </div>
              <div class="form-group">
                <label for="editPrecio">Precio</label>
                <input
                  type="number"
                  v-model="editPrecio"
                  id="editPrecio"
                  placeholder="Precio"
                  required
                />
              </div>
              <div class="form-group">
                <label for="editCategoria">Categoría</label>
                <input
                  type="text"
                  v-model="editCategoria"
                  id="editCategoria"
                  placeholder="Categoría"
                  required
                />
              </div>
              <div class="form-group">
                <label for="editImagen">Imagen</label>
                <input
                  type="file"
                  @change="handleEditFileChange"
                  id="editImagen"
                />
              </div>
              <div class="action-btns">
                <button type="submit" class="btn update">Actualizar Producto</button>
                <button @click="cancelEdit" class="btn cancel">Cancelar</button>
              </div>
            </form>
            <div class="preview-container">
              <h3>Previsualización del Producto</h3>
              <div class="producto-card">
                <img
                  :src="editImagenUrl || editProducto.imagen"
                  alt="Previsualización de la imagen"
                  class="producto-imagen"
                />
                <div class="producto-detalles">
                  <div class="producto-header">
                    <h2 class="producto-nombre">{{ editNombre }}</h2>
                    <p class="producto-precio">{{ formatPrice(editPrecio) }}</p>
                  </div>
                  <div class="rating-btn">
                    <div class="producto-rating">
                      <span v-for="n in 5" :key="n" class="star" :class="{ filled: n <= 5 }">&#9733;</span>
                    </div>
                    <button class="producto-boton">Comprar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api";
import Navbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Navbar,
  },

  data() {
    return {
      productos: [],
      filteredProductos: [],
      searchQuery: "",
      loading: true,
      error: null,
      isEditing: false,
      editProducto: null,
      editNombre: "",
      editDescripcion: "",
      editPrecio: 0,
      editCategoria: "",
      editImagen: null,
      editImagenUrl: null,
    };
  },
  async created() {
    await this.loadProductos();
  },
  methods: {
    async loadProductos() {
      try {
        const response = await apiClient.get("/productos/all");
        this.productos = response.data;
        this.filteredProductos = this.productos; // Inicializa la lista filtrada
      } catch (error) {
        this.error = "Error al cargar los productos.";
      } finally {
        this.loading = false;
      }
    },
    filterProductos() {
      const query = this.searchQuery.toLowerCase();
      this.filteredProductos = this.productos.filter(
        (producto) =>
          producto.nombre.toLowerCase().includes(query) ||
          producto.id.toString().includes(query)
      );
    },
    editProduct(producto) {
      this.isEditing = true;
      this.editProducto = producto;
      this.editNombre = producto.nombre;
      this.editDescripcion = producto.descripcion;
      this.editPrecio = producto.precio;
      this.editCategoria = producto.categoria;
      this.editImagenUrl = producto.imagen;
    },
    handleEditFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.editImagen = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.editImagenUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async updateProduct() {
      try {
        const formData = new FormData();
        formData.append("nombre", this.editNombre);
        formData.append("descripcion", this.editDescripcion);
        formData.append("precio", this.editPrecio);
        formData.append("categoria", this.editCategoria);
        if (this.editImagen) {
          formData.append("imagen", this.editImagen);
        }

        await apiClient.put(`/productos/${this.editProducto.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        alert("Producto actualizado correctamente.");
        this.isEditing = false;
        this.editProducto = null;
        this.editImagenUrl = null;
        await this.loadProductos(); // Recargar la lista de productos
      } catch (error) {
        console.error("Error al actualizar producto:", error);
        alert("Error al actualizar producto.");
      }
    },
    cancelEdit() {
      this.isEditing = false;
      this.editProducto = null;
      this.editImagenUrl = null;
    },
    async toggleStatus(producto) {
      const newStatus = producto.status === 1 ? 0 : 1;
      try {
        await apiClient.put(`/productos/status/${producto.id}`, {
          status: newStatus,
        });
        alert(
          `Producto ${
            newStatus === 1 ? "activado" : "desactivado"
          } correctamente.`
        );
        await this.loadProductos(); // Recargar la lista de productos
      } catch (error) {
        console.error("Error al cambiar el estado del producto:", error);
        alert("Error al cambiar el estado del producto.");
      }
    },
    async deleteProduct(id) {
      if (confirm("¿Estás seguro de que deseas eliminar este producto?")) {
        try {
          await apiClient.delete(`/productos/${id}`);
          alert("Producto eliminado correctamente.");
          await this.loadProductos(); // Recargar la lista de productos
        } catch (error) {
          console.error("Error al eliminar producto:", error);
          alert("Error al eliminar producto.");
        }
      }
    },
    formatPrice(value) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },
  },
};
</script>

<style scoped>
.edit-products {
  padding-top: 80px; /* Para compensar la altura del navbar */
  padding-bottom: 20px;
}

.title {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.search-bar {
  margin-bottom: 20px;
  text-align: center;
}

.search-bar input {
  padding: 10px;
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.productos-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.producto {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  width: 220px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.producto-imagen {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.producto-detalles h3 {
  font-size: 1.2em;
  margin: 10px 0;
  font-weight: bold;
}

.producto-detalles p {
  margin: 5px 0;
  font-size: 0.9em;
}

.action-btns {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.action-btns + .action-btns {
  margin-top: 5px; /* Espacio entre las filas de botones */
}

.btn {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}

.btn.delete {
  background-color: #dc3545;
}

.btn-status {
  flex-grow: 1;
}

.btn.desactivar {
  background-color: #ffc107;
}

.btn.activar {
  background-color: #28a745;
}

.btn:hover {
  opacity: 0.9;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 900px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.edit-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.product-form {
  flex: 1;
}

.preview-container {
  flex: 1;
}

.product-form .form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box; /* Asegura que el padding se incluya en el ancho total */
  font-size: 1em;
}

.action-btns {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.btn.update {
  background-color: #28a745; /* Verde para actualizar */
}

.btn.cancel {
  background-color: #6c757d; /* Gris para cancelar */
}

.btn.update:hover,
.btn.cancel:hover {
  opacity: 0.9;
}

.producto-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  background-color: #fff; /* Fondo blanco */
  transition: box-shadow 0.3s ease;
}

.producto-imagen {
  width: 100%;
  height: 20em; /* Altura fija para las imágenes */
  object-fit: cover; /* Recorta las imágenes para que se ajusten */
  border-radius: 10px;
}

.producto-detalles {
  padding: 10px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Permitir que crezca para alinear el botón al fondo */
}

.producto-nombre {
  font-size: 1.2em;
  margin: 10px 0 5px 0;
  font-weight: 300; /* Tipografía más delgada */
}

.producto-precio {
  font-size: 1.1em;
  margin: 0 0 10px 0;
  font-weight: 200; /* Tipografía más delgada */
}

.producto-rating {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.star {
  font-size: 1.2em;
  color: #ddd;
}

.star.filled {
  color: #ffc107;
}

.rating-btn {
  margin-top: auto;
}

.producto-boton {
  background-color: #fff; /* Botón blanco */
  color: #000; /* Letras negras */
  border: 1px solid #000; /* Borde negro */
  padding: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: auto; /* Margen superior automático para fijar el botón al fondo */
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.producto-boton:hover {
  background-color: #7f5a35; /* Color café al hacer hover sobre el botón */
  color: #fff; /* Letras blancas al hacer hover sobre el botón */
  border: 1px solid #7f5a35; /* Borde café al hacer hover sobre el botón */
}
</style>
