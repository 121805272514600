<template>
  <div class="terminos-container">
    <Navbar />
    <div class="terminos">
      <h1>Términos y Condiciones</h1>
      <p>Fecha de entrada en vigor: 11/10/2024</p>

      <p>
        Bienvenido a BaliSolarium, un servicio proporcionado por BaliSolarium
        S.P.A.. Al utilizar nuestros servicios, realizar compras y/o agendar
        sesiones a través de nuestro sitio web, app o cualquier otra plataforma,
        aceptas los términos y condiciones descritos a continuación. Por favor,
        lee cuidadosamente este acuerdo antes de proceder.
      </p>

      <h2>1. Generalidades</h2>
      <p>
        1.1. BaliSolarium ofrece servicios de bronceado a través de nuestras
        instalaciones, permitiendo a los usuarios la compra de sesiones y el
        agendamiento de citas utilizando el RUT (Rol Único Tributario) como
        identificador.
      </p>
      <p>
        1.2. Al utilizar nuestros servicios, aceptas cumplir con estos términos
        y condiciones en su totalidad.
      </p>
      <p>
        1.3. BaliSolarium S.P.A. se reserva el derecho de modificar estos
        términos y condiciones en cualquier momento. Las modificaciones serán
        notificadas a los usuarios a través de nuestros canales oficiales y
        entrarán en vigor a partir de la fecha de su publicación.
      </p>

      <h2>2. Usuarios y Agendamiento</h2>
      <p>
        2.1. Los usuarios no requieren la creación de una cuenta con contraseña
        para acceder a los servicios de BaliSolarium. El RUT del usuario será
        utilizado para realizar las compras y agendar sesiones.
      </p>
      <p>
        2.2. Es responsabilidad del usuario proporcionar información precisa,
        incluyendo su RUT, datos de contacto y otros detalles requeridos para
        agendar sesiones y realizar pagos.
      </p>
      <p>
        2.3. El usuario se compromete a mantener su información actualizada,
        incluyendo su número de teléfono y correo electrónico, para recibir
        notificaciones y confirmaciones.
      </p>

      <h2>3. Compra de Servicios</h2>
      <p>
        3.1. BaliSolarium ofrece la venta de sesiones de bronceado, que pueden
        ser adquiridas a través de nuestra plataforma en línea. Los precios y la
        disponibilidad están sujetos a cambios sin previo aviso.
      </p>
      <p>
        3.2. Los pagos son gestionados por la pasarela de pagos externa Getnet.
        Al proporcionar la información de pago, el usuario acepta las políticas
        de privacidad y términos de uso de Getnet. BaliSolarium no almacena los
        detalles de las tarjetas de crédito o débito.
      </p>
      <p>
        3.3. Política de reembolso: Las compras de sesiones no son
        reembolsables. En caso de error en la compra, el usuario debe
        contactarnos de inmediato para evaluar su caso. Sin embargo,
        BaliSolarium no garantiza el reembolso.
      </p>
      <p>
        3.4. Validez de las sesiones: Las sesiones adquiridas no tienen fecha de
        vencimiento. El usuario puede utilizarlas en cualquier momento, siempre
        sujeto a la disponibilidad de horarios para agendar.
      </p>

      <h2>4. Agendamiento y Cancelaciones</h2>
      <p>
        4.1. Los usuarios pueden agendar sesiones a través de nuestra
        plataforma, sujeto a disponibilidad de horarios.
      </p>
      <p>
        4.2. Las sesiones deben ser canceladas o modificadas con al menos 24
        horas de antelación. Las cancelaciones o modificaciones deben realizarse
        enviando una notificación por correo electrónico a
        contacto@balisolarium.cl o llamando al +56 934800610.
      </p>
      <p>
        4.3. No asistencia: En caso de que el usuario no asista a la sesión
        agendada sin cancelar o modificar dentro del plazo indicado, la sesión
        será considerada como utilizada y no se ofrecerá la opción de reembolso
        o reprogramación.
      </p>

      <h2>5. Condiciones de Uso de las Instalaciones</h2>
      <p>
        5.1. BaliSolarium se reserva el derecho de negar el acceso a las
        instalaciones a cualquier usuario que no cumpla con las instrucciones de
        seguridad proporcionadas en nuestras instalaciones.
      </p>
      <p>
        5.2. Los usuarios deben seguir todas las indicaciones del personal de
        BaliSolarium para garantizar el uso adecuado y seguro de las cabinas de
        bronceado.
      </p>
      <p>
        5.3. Exención de responsabilidad: El usuario acepta que el uso de las
        cabinas de bronceado es bajo su propio riesgo. BaliSolarium S.P.A. no se
        hace responsable por lesiones, daños o reacciones adversas relacionadas
        con el uso del servicio de bronceado.
      </p>

      <h2>6. Salud y Seguridad</h2>
      <p>
        6.1. BaliSolarium sigue protocolos estrictos de limpieza y desinfección
        en todas las instalaciones para asegurar la seguridad de los usuarios.
      </p>
      <p>
        6.2. Los usuarios deben informar al personal si padecen alguna condición
        médica que pudiera verse afectada por el uso del servicio de bronceado.
      </p>
      <p>
        6.3. Limitación de responsabilidad: BaliSolarium S.P.A. no se hace
        responsable de reacciones adversas o complicaciones de salud derivadas
        del uso de nuestros servicios, especialmente si el usuario no ha
        informado de condiciones médicas relevantes.
      </p>

      <h2>7. Pagos y Seguridad</h2>
      <p>
        7.1. Los pagos se realizan a través de la plataforma Getnet, la cual
        maneja la información financiera de manera segura.
      </p>
      <p>
        7.2. BaliSolarium no almacena información de tarjetas de crédito o
        débito de los usuarios y se exime de cualquier responsabilidad
        relacionada con el manejo de dicha información.
      </p>
      <p>
        7.3. En caso de error o disputa en el procesamiento de un pago, el
        usuario debe contactar a nuestro equipo de atención al cliente.
      </p>

      <h2>8. Propiedad Intelectual</h2>
      <p>
        8.1. Todos los derechos de propiedad intelectual relacionados con el
        sitio web, aplicaciones móviles, marcas y contenido de BaliSolarium son
        propiedad de BaliSolarium S.P.A.. El uso no autorizado de cualquier
        contenido está estrictamente prohibido.
      </p>

      <h2>9. Modificaciones y Terminación del Servicio</h2>
      <p>
        9.1. BaliSolarium S.P.A. se reserva el derecho de modificar, suspender o
        terminar cualquier servicio, en cualquier momento, sin previo aviso.
      </p>
      <p>
        9.2. En caso de terminación del servicio, los usuarios serán notificados
        con antelación y se les ofrecerán opciones para la utilización o
        reembolso de cualquier sesión pendiente.
      </p>

      <h2>10. Resolución de Disputas</h2>
      <p>
        10.1. Cualquier disputa o reclamo relacionado con el uso de los
        servicios de BaliSolarium será resuelta de conformidad con las leyes de
        Chile, donde se encuentra registrada BaliSolarium S.P.A..
      </p>
      <p>
        10.2. Los usuarios aceptan que cualquier disputa será resuelta de manera
        individual y no se permitirán acciones colectivas contra BaliSolarium
        S.P.A..
      </p>

      <h2>11. Contacto</h2>
      <p>
        Si tienes alguna duda o consulta sobre estos Términos y Condiciones,
        puedes contactarnos en:
      </p>
      <p>BaliSolarium S.P.A.</p>
      <p>Dirección: Av. Gabriela Mistral 1386</p>
      <p>Teléfono: +56 934800610</p>
      <p>Email: contacto@balisolarium.cl</p>

      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from "../components/NavbarTop.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "TerminosCondiciones",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>
.terminos-container {
  background-color: #f5f5f5;
}

.terminos {
  max-width: 90%;
  margin: auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 8em;
}

h1,
h2 {
  color: #333;
}

p {
  margin-bottom: 20px;
  line-height: 1.6;
}
</style>
