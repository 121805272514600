<template>
  <div class="login-container">
    <div class="login-box">
      <img src="@/assets/bali_main.png" alt="Logo" class="logo" />
      <h2>Admin Login</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <input type="text" v-model="username" placeholder="Username" />
        </div>
        <div class="form-group">
          <input type="password" v-model="password" placeholder="Password" />
        </div>
        <button type="submit" class="login-btn">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api";

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async login() {
      try {
        const response = await apiClient.post("/login", {
          username: this.username,
          password: this.password,
        });

        if (response.data.message === "Login successful") {
          localStorage.setItem("adminToken", response.data.token); // Almacenar el token
          this.$router.push("/admin/dashboard"); // Redirigir al dashboard
        }
      } catch (error) {
        console.error("Error en el inicio de sesión:", error);
        alert("Error en el inicio de sesión. Verifica tus credenciales.");
      }
    },
  },
};
</script>


<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-box {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 30em;
}

.logo {
  max-width: 100px;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  margin-right: 1.5em;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

input:focus {
  outline: none;
  border-color: #007bff;
}

.login-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  width: 100%;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #0056b3;
}
</style>
