<template>
    <div class="admin-dashboard">
      <Navbar />
      <div class="content-grid">
        <div class="form-section">
          <h2>Agregar Producto</h2>
          <form @submit.prevent="submitProduct" class="product-form">
            <div class="form-group">
              <label for="nombre">Nombre del producto</label>
              <input type="text" v-model="nombre" id="nombre" placeholder="Nombre del producto">
            </div>
            <div class="form-group">
              <label for="descripcion">Descripción</label>
              <textarea v-model="descripcion" id="descripcion" placeholder="Descripción"></textarea>
            </div>
            <div class="form-group">
              <label for="precio">Precio</label>
              <input type="number" v-model="precio" id="precio" placeholder="Precio">
            </div>
            <div class="form-group">
              <label for="categoria">Categoría</label>
              <input type="text" v-model="categoria" id="categoria" placeholder="Categoría">
            </div>
            <div class="form-group">
              <label for="imagen">Imagen</label>
              <input type="file" @change="handleFileChange" id="imagen">
            </div>
            <button type="submit" class="btn btn-primary">Agregar Producto</button>
          </form>
        </div>
  
        <div class="preview-section">
          <h3>Vista previa del producto</h3>
          <div class="product-preview">
            <img :src="imagenUrl" alt="Imagen del producto" class="producto-imagen"/>
            <div class="producto-detalles">
              <div class="producto-header">
                <h2 class="producto-nombre">{{ nombre }}</h2>
                <p class="producto-precio">{{ formatPrice(precio) }}</p>
              </div>
              <div class="producto-rating">
                <span v-for="n in 5" :key="n" class="star" :class="{ filled: n <= 5 }">&#9733;</span>
              </div>
              <button class="producto-boton">Comprar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import apiClient from '@/api';
  import Navbar from '@/components/AdminNavbar.vue';
  
  export default {
    components: {
      Navbar
    },
  
    data() {
      return {
        nombre: '',
        descripcion: '',
        precio: 0,
        categoria: '',
        imagen: null,
        imagenUrl: '', // Añadido para la vista previa de la imagen
      };
    },
    methods: {
      async submitProduct() {
        try {
          const formData = new FormData();
          formData.append('nombre', this.nombre);
          formData.append('descripcion', this.descripcion);
          formData.append('precio', this.precio);
          formData.append('categoria', this.categoria);
          formData.append('imagen', this.imagen);
  
          await apiClient.post('/productos', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
  
          alert('Producto agregado correctamente.');
          this.resetForm();
        } catch (error) {
          console.error('Error al agregar producto:', error);
          if (error.response && error.response.status === 401) {
            // Redirigir al login si la sesión no está autenticada
            this.$router.push('/admin');
          } else {
            alert('Error al agregar producto.');
          }
        }
      },
      handleFileChange(event) {
        this.imagen = event.target.files[0];
        this.imagenUrl = URL.createObjectURL(this.imagen); // Mostrar la vista previa de la imagen
      },
      resetForm() {
        this.nombre = '';
        this.descripcion = '';
        this.precio = 0;
        this.categoria = '';
        this.imagen = null;
        this.imagenUrl = ''; // Resetear la vista previa de la imagen
      },
      formatPrice(value) {
        return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
      }
    }
  };
  </script>
  
  <style scoped>
  .admin-dashboard {
    padding-top: 60px; /* Para compensar la altura del navbar */
  }
  
  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-section {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .product-form .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input, .form-group textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
  }
  
  .btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #007BFF;
    color: white;
  }
  
  .preview-section {
    position: sticky;
    top: 80px; /* Ajusta esto según la altura del navbar */
    align-self: flex-start;
  }
  
  .product-preview {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  
  .product-preview img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .product-preview .producto-detalles {
    padding: 10px;
    width: 100%;
  }
  
  .product-preview .producto-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .product-preview .producto-nombre {
    font-size: 1.2em;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .product-preview .producto-precio {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
    text-align: right;
  }
  
  .product-preview .producto-rating {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
  }
  
  .product-preview .star {
    font-size: 1.2em;
    color: #ddd;
  }
  
  .product-preview .star.filled {
    color: #ffc107;
  }
  
  .product-preview .producto-boton {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: auto;
    border-radius: 5px;
  }
  
  .product-preview .producto-boton:hover {
    background-color: #333;
  }
  </style>
  